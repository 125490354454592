// moved polyfills to cdn version on index html in first script tag to ensure loading before all other scripts - anthony
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import React from "react";
import ReactDOM from "react-dom";
import history from "./History";
import { Router } from "react-router-dom";
import routes from "./Routes";

import "./Pages/includes/onlinebooking.css?version=20190520-3";
import { ErrorBoundary } from "./BugSnag";

import { AccountContextProvider } from "./Contexts/account";
import { AppointmentContextProvider } from "./Contexts/appointment";
import { LocationContextProvider } from "./Contexts/location";

ReactDOM.render(
  <ErrorBoundary>
    <AccountContextProvider>
      <AppointmentContextProvider>
        <LocationContextProvider>
          <div className="OnlineBooking get-started-branch"> 
            <Router history={history}>{routes}</Router> 
          </div>
        </LocationContextProvider>
      </AppointmentContextProvider>
    </AccountContextProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
